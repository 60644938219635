<template>
  <div>
    <div class="mb-10">
      <div class="text-lg font-bold mb-5">
        Referrals (Share and Earn)
      </div>
    </div>

    <div class="grid grid-cols-1 mb-4">
      <search-form
        v-model="searchQuery"
        placeholder="Search for user's name, status, etc."
        @submit="(event) => $refs.table.loadAjaxData()"
      />
    </div>
    <datatable
      :url="url"
      :ajax="true"
      :ajaxPagination="true"
      :index="true"
      :reverse-index="true"
      :columns="referralsColumns"
      :pageDetails="true"
      :fillable="true"
      :loading="loading"
      :query="searchQuery"
      dropdown="actions"
      ref="table"
    />
  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      url: `${this.$baseurl}/admin/personal/users/${this.staff?.id}/referrals`,
      loading: false,
      error: false,
      searchQuery: "",
      referralsColumns: [
        {
          name: "full_name",
          th: "Name",
          render: (referrals) => {
            return referrals?.referred?.full_name;
          },
        },
        {
          name: "created_date",
          th: "Registration Date",
          render: (referrals) => {
            return this.$options.filters.dateFormat(
              referrals?.referred?.created_date,
              "D, dd M Y"
            );
          },
        },
        {
          name: "status",
          th: "Status",
          render: (referrals) => {
            switch (referrals.status) {
              case "approved":
                return `<div class="badge badge-green-soft-outline">
                    Approved
                  </div>`;
              case "incomplete-signup":
                return `<div class="badge badge-blue-soft-outline">
                    Incomplete-signup
                  </div>`;
              case "declined":
                return `<div class="badge badge-red-soft">
                    Declined
                  </div>`;
              case "processing":
              default:
                return `<div class="badge badge-orange-soft-outline">
                    Processing
                  </div>`;
            }
          },
        },
      ],
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
};
</script>
